// src/context/AuthContext.js
import React, { createContext, useContext, useState } from "react";
import { authLogin } from "../services/auth.service";

type AuthContextType = {
  user: string | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  isAuthenticated: boolean;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken")
  );
  const [idToken, setIdToken] = useState(localStorage.getItem("idToken"));
  const [tokenExpiration, setTokenExpiration] = useState(
    localStorage.getItem("accessTokenExpiresOn")
  );

  // Login function
  const login = async (username: string, password: string) => {
    try {
      const response = await authLogin({ username, password });
      const { accessToken, expiresOn, refreshToken, idToken } = response.data;
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setIdToken(idToken);
      setTokenExpiration(expiresOn);
      // setUser(user);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("tokenExpiration", expiresOn);
    } catch (error: any) {
      console.error("Login failed:", error.response?.data || error.message);
      throw error;
    }
  };

  // Logout function
  const logout = () => {
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
    setIdToken(null);
    setTokenExpiration(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("tokenExpiration");
  };

  // Check authentication status
  const isAuthenticated = !!accessToken;

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  // If the context is null, throw an error to ensure it's only used within an AuthProvider
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
