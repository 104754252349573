import React, { useState, useEffect } from "react";
import Dropdown from "../../../components/Dropdown";

import "./dropdown-config-container.scss";
import Button from "../../../components/Button";
import Select from "react-select";
import { appInsights } from "../../../services/app-insights.service";

const TimeContainer = () => {
  return (
    <div className="analyse__time">
      <h2 className="analyse__time-title">+ Over This Time Period:</h2>
      <div className="analyse__time-container"></div>
    </div>
  );
};

const BreakdownContainer = () => {
  return (
    <div className="analyse__breakdown-parent">
      <h2 className="analyse__breakdown-title">+ And breakdown by this:</h2>
      <div className="analyse__breakdown-container"></div>
    </div>
  );
};

const DropdownConfig = (props: any) => {
  const { originalFieldsData, fieldsData, setFieldsData, loading } = props;
  const [fieldsArrayChild, setFieldsArrayChild] = useState([]);

  // set fieldArray to data from endpoint

  useEffect(() => {
    if (!fieldsData) return;
    setFieldsArrayChild(fieldsData);
  }, [fieldsData]);

  // Change fieldsArray to what has been selected

  const handleChange = (index: number, selectedOption: any) => {
    const updatedFieldsArray: any = fieldsArrayChild.map(
      (item: any, i: number) => {
        if (i === index) {
          // send event to Application Insights
          appInsights.trackEvent({
            name: "dashboardDropdownSelectionEvent",
            properties: {
              dashboardDropdownSelectionEvent: {
                name: item.name,
                category: item.category,
                selected: selectedOption.value,
              },
              user: `${localStorage.getItem("email")}`,
            },
          });

          return {
            ...item,
            selected: selectedOption.value, // Save the selected value
          };
        }
        return item;
      }
    );

    setFieldsArrayChild(updatedFieldsArray);
    setFieldsData(updatedFieldsArray);
  };

  // Old handleSelect function for non-searchable dropdowns

  const handleSelect = (index: number, value: string) => {
    const updatedFieldsArray: any = fieldsArrayChild.map(
      (item: any, i: number) => {
        if (i === index) {
          return {
            ...item,
            selected: value,
          };
        }
        return item;
      }
    );

    setFieldsArrayChild(updatedFieldsArray);
    setFieldsData(updatedFieldsArray);
  };

  // Analyse button click

  const handleAnalyseClick = () => {
    console.log({ fields: fieldsArrayChild });
  };

  const colorStyles = (selectedValue: string, defaultValue: string | null) => ({
    control: (provided: any) => ({
      ...provided,
      backgroundColor:
        selectedValue != defaultValue
          ? process.env.REACT_APP_ENV_COLOR
          : "#FFFFFF", // Change the background color based on value
    }),
  });

  return (
    <>
      {loading ? (
        <div className="loading-spinner" />
      ) : (
        <div className="analyse">
          <Button
            text={"Analyse"}
            onClick={() => handleAnalyseClick()}
            style={{
              width: "100px",
              marginTop: "18px",
              marginBottom: "24px",
              marginLeft: "auto",
              marginRight: "24px",
              "--button-bg-color": process.env.REACT_APP_ENV_COLOR,
            }}
          />
          {/* <h2 className="analyse__title">Analyse This...</h2> */}
          <div className="analyse__container">
            <div className="analyse__breakdown">
              {fieldsArrayChild &&
                fieldsArrayChild.length > 0 &&
                fieldsArrayChild.map((item: any, index: number) => {
                  const { options, selected, category, name } = item;
                  const defaultValue = originalFieldsData.filter(
                    (x: any) => x.name == name
                  )[0]?.selected;

                  // SEARCHABLE DROPDOWNS Transform the options array from strings to objects with value and label
                  const formattedOptions = options.map((option: string) => ({
                    value: option,
                    label: option,
                  }));

                  // SEARCHABLE DROPDOWNS Find the selected option object from the options list
                  const selectedOption = formattedOptions.find(
                    (option: any) => option.value === selected
                  );

                  return (
                    <div className="analyse__item" key={index}>
                      <div className="analyse__title">
                        <div className="analyse__name">{item.name}</div>
                        <div className="analyse__category">
                          Category {JSON.stringify(item.category)}
                        </div>
                      </div>
                      <Select
                        key={index}
                        value={selectedOption}
                        onChange={(selectedOption) =>
                          handleChange(index, selectedOption)
                        }
                        options={formattedOptions}
                        styles={colorStyles(selected, defaultValue)}
                      />
                      {/* <Dropdown
                    index={index}
                    // label={item.name}
                    options={sortedOptions}
                    selectedValue={item.selected}
                    onSelect={handleSelect}
                  /> */}
                    </div>
                  );
                })}
            </div>
          </div>
          {/* <TimeContainer />
      <BreakdownContainer /> */}
        </div>
      )}
    </>
  );
};

export default DropdownConfig;
