import React, { useState, useEffect, useRef } from "react";
import "./expanding-textarea-container.scss";

interface ExpandingTextareaProps {
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  disabled: boolean;
  maxLength: number;
  onKeyDown: (newValue: any) => void;
}

const ExpandingTextarea: React.FC<ExpandingTextareaProps> = ({
  value,
  onChange,
  placeholder,
  style,
  disabled,
  maxLength,
  onKeyDown,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      // Reset height to allow upward expansion
      textareaRef.current.style.height = "auto";
      // Set the height based on the scroll height to accommodate the content
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <textarea
      data-testid="expanding-textarea"
      className="textarea"
      ref={textareaRef}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      style={{ ...style, resize: "none", overflow: "hidden" }} // Disable manual resizing and hide scrollbars
      disabled={disabled}
      maxLength={maxLength}
      onKeyDown={onKeyDown}
    />
  );
};

export default ExpandingTextarea;
