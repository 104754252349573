export function decodeJwt(token) {
    const [header, payload, signature] = token.split('.');

    // Decode the Base64 payload, handling URL-safe characters
    var stringToDecode = payload?.replace(/-/g, '+').replace(/_/g, '/');
    if(stringToDecode == null)
        return null;

    const decodedPayload = atob(stringToDecode);

    // Parse the JSON payload to an object
    return JSON.parse(decodedPayload);
}