import React, { useState, useEffect } from "react";
import "./welcome-name-container.scss";
import { decodeJwt } from "../../utils/jwt-decoder";

const WelcomeName = () => {
  // const { instance } = useMsal();
  const [username, setUsername] = useState("");

  useEffect(() => {
    const decoded = decodeJwt(localStorage.getItem("idToken") ?? "");
    setUsername(decoded?.name);
  });

  return (
    <div className="username" data-testid="welcome-name">
      {username}
    </div>
  );
};

export default WelcomeName;
