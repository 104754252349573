import axios from "axios";

const backend = process.env.REACT_APP_BE_URL || "";
const authEndpoint = `${backend}/api/auth`;

export const authLogin = async (credentials: any): Promise<any> => {
  const response = await axios.post(`${authEndpoint}/login`, credentials);
  return response;
};

export const authRefreshToken = async (refreshToken: any): Promise<any> => {
  const response = await axios.post(`${authEndpoint}/refresh`, {
    refreshToken,
  });
  return response;
};

export const resetPassword = async (credentials: any): Promise<any> => {
  const response = await axios.post(
    `${authEndpoint}/reset-password`,
    credentials
  );
  return response;
};

export const forgotPassword = async (emailAddress: string): Promise<any> => {
  const response = await axios.post(
    `${authEndpoint}/forgot-password`,
    { email: emailAddress }
  );
  return response;
};
