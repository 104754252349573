import React, { useState, useEffect, useRef } from "react";
import "./expandable-text-container.scss";

const ExpandableText = ({ text, maxLength = 100 } : any) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle expanded state
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // Check if the text is longer than the max length
  const isTextLong = text.length > maxLength;

  // Shortened version of the text for when it's collapsed
  const displayText = isExpanded ? text : text.substring(0, maxLength) + (isTextLong ? "..." : "");

  return (
    <div id="expandable-div">
      <br />
      <span style={{display: isExpanded ? 'block' : 'none'}}>{displayText}</span>
      {isTextLong && (
        <a onClick={toggleExpanded} id="readMoreButton">
          {isExpanded ? "Hide" : "Why ?"}
        </a>
      )}
    </div>
  );
};

export default ExpandableText;
