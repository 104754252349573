import { axiosRequestInterceptor } from "./api";

export const getDashboardData = async () => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.get(
    "api/dashboard/configdb/multiselect/reset"
  );
  return response;
};

export const postMultiSelect = async (params: any) => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.post(
    "api/dashboard/configdb/multiselect",
    params
  );
  return response;
};
