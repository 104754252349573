import React, { useState, useEffect } from "react";
import "./input-page-container.scss";

import ChatInput from "./ChatInput";
import DropdownConfig from "./DropdownConfig";
import { appInsights } from "../../services/app-insights.service";
import { getDashboardData } from "../../services/dashboard.service";
import { useLocation } from "react-router-dom";

const backend = process.env.REACT_APP_BE_URL || "";

const InputPage = ({ chatGPTModel, setChatGPTModel }: any) => {
  const [fieldsData, setFieldsData]: any = useState(null);
  const [originalFieldsData, setOriginalFieldsData]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [defaultWeek, setDefaultWeek]: any = useState(null);

  // Get Request - initial state for dropdowns

  const getFieldsInitialState = async () => {
    try {
      const data = await getDashboardData();
      setFieldsData(data.data.fields);

      let cloneData = structuredClone(data.data.fields);
      setOriginalFieldsData(cloneData);
      setDefaultWeek(
        cloneData?.filter((x: any) => x.name == "to_week")[0]?.selected
      );

      setLoading(false);

      appInsights.trackEvent({
        name: "resetResponse",
        properties: {
          apiResetResponsePayload: data,
          user: `${localStorage.getItem("email")}`,
        },
      });
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    getFieldsInitialState();
  }, []);

  return (
    <div className="input-page-container">
      <ChatInput
        dropdownFieldsParent={fieldsData}
        setFieldsData={setFieldsData}
        getFieldsInitialState={getFieldsInitialState}
        loading={loading}
        setLoading={setLoading}
        chatGPTModel={chatGPTModel}
        setChatGPTModel={setChatGPTModel}
        setDefaultWeek={setDefaultWeek}
        defaultWeek={defaultWeek}
      />
      <DropdownConfig
        originalFieldsData={originalFieldsData}
        fieldsData={fieldsData}
        setFieldsData={setFieldsData}
        loading={loading}
      />
    </div>
  );
};

export default InputPage;
