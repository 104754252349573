import React, { useState } from "react";
import "./nav-container.scss";

import { Link } from "react-router-dom";
import { FaGear } from "react-icons/fa6";
import { SlArrowLeft } from "react-icons/sl";
import WelcomeName from "../WelcomeName/WelcomeName";

import Button from "../Button/Button";
import { appInsights } from "../../services/app-insights.service";
import { useAuth } from "../../context/auth.context";

const NavCollapsed = ({ setCollapseNav, collapseNav }: any) => {
  return (
    <div className="nav__collapsed-container">
      <SlArrowLeft
        className="nav__toggle-icon"
        onClick={() => setCollapseNav(collapseNav)}
      />
    </div>
  );
};

const Nav = (props: any) => {
  const { setCollapseNav, collapseNav } = props;
  const { logout } = useAuth();

  const handleLogout = () => {
    appInsights.trackEvent({
      name: "ButtonClicked",
      properties: {
        buttonName: "LogoutButton",
        user: `${localStorage.getItem("email")}`,
      },
    });
    logout();
  };

  return (
    <>
      {!collapseNav ? (
        <div className="nav">
          <div className="nav__logo" />
          <div className="nav__icons-container">
            <FaGear className="nav__settings-icon" data-testid="gear-icon" />
            <SlArrowLeft
              className="nav__toggle-icon"
              onClick={() => setCollapseNav(collapseNav)}
            />
          </div>
          <div className="nav__block">
            <ul className="nav__list">
              <li className="nav__item">
                <Link to="/">Input</Link>
              </li>
              {/* <li className="nav__item">
                <Link to="/master-table">Master Table</Link>
              </li> */}
              {/* <li className="nav__item">
                <Link to="/report">Report</Link>
              </li> */}
            </ul>
          </div>
          <div className="nav__block--user">
            <ul className="nav__list">
              <li className="nav__item--welcome">
                <WelcomeName />
              </li>
              <Button
                onClick={() => handleLogout()}
                text={"Log Out"}
                style={{ "--button-bg-color": process.env.REACT_APP_ENV_COLOR }}
              />
            </ul>
          </div>
        </div>
      ) : (
        <NavCollapsed
          setCollapseNav={setCollapseNav}
          collapseNav={collapseNav}
        />
      )}
    </>
  );
};

export default Nav;
