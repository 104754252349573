import React, { useEffect, useState, useRef } from "react";

import "./chat-input-container.scss";
import { FaArrowCircleUp } from "react-icons/fa";
import ExpandingTextarea from "../../../components/ExpandingTextarea";
import axios from "axios";
import Button from "../../../components/Button";
import { appInsights } from "../../../services/app-insights.service";
import ExpandableText from "../../../components/ExpandableText";
import { postMultiSelect } from "../../../services/dashboard.service";

const ChatInput = (props: any) => {
  const {
    dropdownFieldsParent,
    setFieldsData,
    getFieldsInitialState,
    loading,
    setLoading,
    chatGPTModel,
    setChatGPTModel,
    defaultWeek,
  } = props;
  const [inputText, setInputText] = useState("");
  const [conversation, setConversation]: any = useState([]);
  const [historyArray, setHistoryArray] = useState([]);
  const [stateSmall, setStateSmall]: [any, any] = useState([]);
  const [stateFull, setStateFull] = useState([]);

  const endOfMessagesRef = useRef<HTMLDivElement>(null);

  // Build historyArray to insert into new userMessage

  useEffect(() => {
    let tempHistoryArray: any = [];
    let messageContent: object;

    if (conversation.length <= 0) return;
    // const lastMessage = conversation[conversation.length - 1];

    // if (lastMessage.dashboard) {
    conversation.forEach((message: any) => {
      if (message.openai) {
        messageContent = message.openai.instruction;
      } else if (message.aiText) {
        messageContent = {
          role: "assistant",
          content: message.aiText?.aiText,
        };
      }
      tempHistoryArray.push(messageContent);
    });
    // } else return;

    setHistoryArray(tempHistoryArray);
  }, [conversation]);

  // Message Submit

  const onMessageSubmit = async () => {
    if (inputText.trim() === "") return;

    try {
      // Create a new user message object
      const newUserMessage = {
        openai: {
          history: historyArray,
          model: chatGPTModel,
          instruction: { role: "user", content: inputText },
        },
        state: {
          fields: [], // Placeholder for any specific state fields, if needed
        },
      };

      // Filter and format payload state from dropdownFieldsParent
      const payloadState = dropdownFieldsParent
        .filter((x: any) => x.selected !== "All")
        .map((obj: any) => ({
          name: obj.name,
          category: obj.category,
          selected: obj.selected,
        }));

      // Add the new user message to the conversation array
      setConversation((prevConversation: any[]) => [
        ...prevConversation,
        newUserMessage,
      ]);

      // Define the request params
      const params = {
        openai: {
          history: historyArray,
          instruction: {
            role: "user",
            content: inputText,
          },
          model: chatGPTModel,
        },
        state: payloadState,
        currenttime: {
          week: defaultWeek,
          year: 2023,
        },
      };

      // Track user message event
      appInsights.trackEvent({
        name: "userMessage",
        properties: {
          messagePayload: params,
          user: `${localStorage.getItem("email")}`,
        },
      });

      // Reset input and show loading
      setInputText("");
      setLoading(true);

      // Make the API call to postMultiSelect
      const result = await postMultiSelect(params);

      // Update conversation with API response
      setConversation((prevConversation: any[]) => [
        ...prevConversation,
        result.data,
      ]);

      // Update the dropdown fields based on the API response
      const updatedFields = dropdownFieldsParent.map((field: any) => {
        const matchingUpdate = result.data.updates.find(
          (update: any) => update.name === field.name
        );
        return {
          ...field,
          selected: matchingUpdate ? matchingUpdate.selected : "All",
        };
      });

      // Update fields and small state
      setFieldsData(updatedFields);
      setStateSmall(result.data.updates);
      setLoading(false);

      // Track API response event
      const tempResponse = { ...result.data }; // Create a copy of the result data
      appInsights.trackEvent({
        name: "chatApiResponse",
        properties: {
          apiChatResponsePayload: tempResponse,
          user: `${localStorage.getItem("email")}`,
        },
      });
    } catch (error) {
      console.error("Error during message submission:", error);
      setLoading(false); // Ensure loading is stopped even in case of an error
    }
  };

  // Autoscroll to bottom of the chat window

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);

  // Enter to submit message

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        return;
      } else event.preventDefault();
      onMessageSubmit();
    }
  };

  const handleNewChatClick = () => {
    getFieldsInitialState();
    setConversation([]);
  };

  const chatGPTModels = [
    { name: "ChatGPT 4o", value: "gpt4o" },
    { name: "ChatGPT 3", value: "gpt3" },
  ];
  const defaultModelIndex = 0;

  return (
    <div className="chat">
      <div className="chat__header">
        <Button
          text={"New Chat"}
          onClick={() => handleNewChatClick()}
          style={{
            width: "100px",
            margin: "18px 24px 0px 24px",
            "--button-bg-color": process.env.REACT_APP_ENV_COLOR,
          }}
        />
        <div className="chat__radio-buttons" style={{ marginRight: "1.5rem" }}>
          {chatGPTModels.map((item: any, index: number) => {
            return (
              <React.Fragment key={item.value}>
                <input
                  type="radio"
                  id={item.value}
                  name="gpt_model"
                  style={{ marginLeft: "0.8rem" }}
                  onClick={() => setChatGPTModel(item.value)}
                  defaultChecked={index == defaultModelIndex}
                />
                <label>{item.name}</label>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className="chat__window">
        {conversation &&
          conversation.length > 0 &&
          conversation.map((message: any, index: number) => {
            if (message.openai) {
              return (
                <div className="chat__user-message" key={index}>
                  {message.openai.instruction.content
                    ?.split("\n")
                    .map((line: any, i: any) => (
                      <span key={i}>
                        {line}
                        <br />
                      </span>
                    ))}
                </div>
              );
            } else {
              return (
                <div className="chat__response-message" key={index}>
                  <div
                    className="chat__response-logo"
                    style={{ whiteSpace: "pre-line" }}
                  />
                  <div>
                    {message.aiText.userText}
                    <ExpandableText
                      text={message.aiText.aiReasoning}
                      maxLength={0}
                    />
                  </div>
                </div>
              );
            }
          })}
        <div ref={endOfMessagesRef} />
      </div>
      <div className="chat__textarea">
        <ExpandingTextarea
          value={inputText}
          onChange={setInputText}
          placeholder="Don't be shy, ask me a question!"
          style={{
            marginLeft: "24px",
            marginRight: "24px",
            paddingRight: "50px",
          }}
          disabled={loading}
          maxLength={1000}
          onKeyDown={handleKeyDown}
        />
        <FaArrowCircleUp
          className="chat__submit"
          onClick={() => onMessageSubmit()}
          style={{
            cursor: loading ? "not-allowed" : "pointer", // Disable pointer interaction
            color: loading ? "grey" : process.env.REACT_APP_ENV_COLOR, // Change color to grey when loading
          }}
        />
      </div>
    </div>
  );
};

export default ChatInput;
