import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../services/auth.service";

import "./forgot-password-container.scss";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [emailAddress, setEmailAddress] = useState<string>("");
  const [error, setError] = useState<string>("");

  async function handleForgotPassword(
    emailAddress: string
  ) {
    try {
      const response = await forgotPassword(emailAddress);
      if (response.status === 200) {
        navigate("/login");
      }
    } catch (error: any) {
      setError(error.response.data);
    }
  }

  const handleEmailChange = (e: any) => {
    const email = e.target.value;
    setEmailAddress(email);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (emailAddress == undefined)
      return;

    await handleForgotPassword(emailAddress);
  };

  return (
    <div className="reset-password">
      <div className="reset-password__logo" />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="email"
            id="email"
            className="form-input"
            placeholder=" "
            onChange={(e) => handleEmailChange(e)}
          />
          <label className="form-label">Email Address</label>
          <p className="error">{error}</p>
        </div>

        <button
          style={
            {
              "--button-bg-color": process.env.REACT_APP_ENV_COLOR,
            } as React.CSSProperties
          }
          type="submit"
          className="reset-password-button"
        >
          Reset Password
        </button>
        <Link className="forgot-password" to="/login">Back to login</Link>
      </form>
    </div>
  );
};

export default ForgotPassword;
