import React from "react";
import "./button-container.scss";

const Button = (props: any) => {
  const { text, onClick, style, type } = props;

  return <button className="button" type={type ?? "button"} style={style} onClick={onClick}>
    {text}
  </button>;
};

export default Button;
