import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/auth.service";

import "./reset-password-container.scss";

type PasswordErrorType =
  | string
  | { description: string }
  | { description: string }[]
  | { errors: { ConfirmPassword?: string[] } }
  | null;

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const userId = queryParams.get("userId");
  const token = queryParams.get("token");

  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<PasswordErrorType>(null);

  useEffect(() => {
    if (!userId || !token) {
      navigate("/login");
    }
  }, []);

  async function handleResetPassword(
    userId: string,
    token: string,
    newPassword: string,
    confirmPassword: string
  ) {
    try {
      const response = await resetPassword({
        userId,
        token,
        newPassword,
        confirmPassword,
      });
      if (response.status === 200) {
        navigate("/login");
      }
    } catch (error: any) {
      console.error("Password change failed:", error);
      if (error.response.data) {
        setPasswordError(error.response.data);
      } else {
        setPasswordError("An unexpected error occurred. Please try again.");
      }
    }
  }

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
  };

  const handleConfirmPasswordChange = (e: any) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      newPassword == undefined ||
      confirmPassword == undefined ||
      userId == undefined ||
      token == undefined
    )
      return;

    await handleResetPassword(userId, token, newPassword, confirmPassword);
  };

  return (
    <div className="reset-password">
      <div className="reset-password__logo" />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="password"
            id="password"
            className="form-input"
            placeholder=" "
            onChange={(e) => handlePasswordChange(e)}
          />
          <label className="form-label">Password</label>
        </div>
        <div className="form-group">
          <input
            type="password"
            id="confirm"
            className="form-input"
            placeholder=" "
            onChange={(e) => handleConfirmPasswordChange(e)}
          />
          <label className="form-label">Confirm Password</label>
        </div>

        <button
          style={
            {
              "--button-bg-color": process.env.REACT_APP_ENV_COLOR,
            } as React.CSSProperties
          }
          type="submit"
          className="reset-password-button"
        >
          Reset Password
        </button>
      </form>
      <div className="reset-password__errors">
        {passwordError &&
          typeof passwordError === "object" &&
          "errors" in passwordError &&
          passwordError.errors?.ConfirmPassword && (
            <div
              style={{
                color: "red",
                fontSize: "14px",
                marginBottom: "12px",
              }}
            >
              {passwordError.errors.ConfirmPassword.map((error, index) => (
                <p key={index} style={{ margin: 0 }}>
                  {error}
                </p>
              ))}
            </div>
          )}

        {passwordError &&
          Array.isArray(passwordError) &&
          passwordError.length > 0 && (
            <div
              style={{
                color: "red",
                fontSize: "14px",
                marginBottom: "12px",
              }}
            >
              {passwordError.map((err, index) => (
                <p key={index} style={{ marginBottom: "3px" }}>
                  {err.description || "An error occurred"}
                </p>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default ResetPassword;
