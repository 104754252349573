import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();

const useTrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    appInsights.trackPageView({ name: location.pathname });
  }, [location]);
};

export { appInsights, reactPlugin, useTrackPageViews };
